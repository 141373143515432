(function ($) {

        function phillips_obj() {
                return {
                        init() {
                                if ($('body').hasClass('home')) {
                                        this.initHeader();
                                        this.resize();
                                        $(window).on('resize', this.resize.bind(this));
                                        $('.animate').removeClass('animate');
                                        setTimeout(() => $('html').addClass('no-delay'), 2000);
                                }
                        },
                        initHeader() {
                                width = window.innerWidth;
                                height = $('.bubbles').outerHeight(true);
                                canvas = document.getElementById('bg-animation');
                                canvas.width = width;
                                canvas.height = height;
                                ctx = canvas.getContext('2d');

                                // create particles
                                circles = [];
                                for (var x = 0; x < width * 0.4; x++) {
                                        var c = new Circle();
                                        circles.push(c);
                                }
                               animate();
                        },
                        resize(e) {
                                width = window.innerWidth;
                                height = $('.bubbles').outerHeight(true);
                                canvas.width = width;
                                canvas.height = height;
                        }
                }
        }
        let p = new phillips_obj()

        $(() => p.init());
})(jQuery);